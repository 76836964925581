import * as Sentry from '@sentry/gatsby'
import { CaptureConsole } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: process.env.SOURCE_VERSION, // https://devcenter.heroku.com/changelog-items/630
  sampleRate: 0.7,
  tracesSampleRate: 0.2,
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  beforeSend(event) {
    if (event.request?.url) {
      event.request.url = event.request.url.replace(/#.*$/, '')
    }
    return event
  },
})
